import { tokenHelper } from "../tokenHelper";
import { featureConfigMap } from "./featureConfig";
import { FLAG_DISABLED_CONFIG } from "./featureFlag.const";

class featureConfigHelperClass {

    getEnvConfigForFeature(flag) {
        const env = process.env.REACT_APP_CONFIG_ENV;
        const flagConfig = featureConfigMap[flag];

        if(flagConfig && flagConfig[env]){
            return flagConfig[env];
        } else {
            console.error(`Could not find config for flag ${flag} in ${env} env`);
            return FLAG_DISABLED_CONFIG;
        }
    }

    isEnabledForOrg(flag, orgId){
        const featureConfigMap = this.getEnvConfigForFeature(flag);
        
        if(featureConfigMap.enabled){
            if(!featureConfigMap.orgs || featureConfigMap.orgs.length === 0){
                return true;
            } else {
                return featureConfigMap.orgs.some(id => id === orgId);
            }
        } else {
            return false;
        }
    }

    isEnabledForCurrentOrg(flag){
        const currentOrgId = tokenHelper.getOrgIdFromAuthToken();
        return this.isEnabledForOrg(flag, currentOrgId);
    }
}

const featureConfigHelper = new featureConfigHelperClass();
export { featureConfigHelper };