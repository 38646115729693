import { DEV, QA, STG, PROD } from "./featureFlag.const";
import { DOWNLOAD_FOR_VCF_OPS_SIGNED_ASSET } from "./featureFlag.const";
/*
    "FLAG_NAME" : {
        [env]: {
            Given an enabled flag with empty orgs array, 
            the feature gets enabled for all orgs in the environment
            enabled: boolean,
            orgs: []
        }
    }
*/

export const featureConfigMap = {
    [DOWNLOAD_FOR_VCF_OPS_SIGNED_ASSET]: {
        [DEV]: {
            enabled: true,
            orgs: []
        },
        [QA]: {
            enabled: true,
            orgs: []
        },
        [STG]: {
            enabled: true,
            orgs: []
        },
        [PROD]: {
            enabled: true,
            orgs: [
                "73190a43-9fd1-4d66-a28d-ac861873a9be",
                "c1f97d42-0c91-4442-a49f-91fe8dcd36c7",
                "4f25e115-8727-474c-b74f-5478607a3df5"
            ]
        }
    }
};