export const DEV = "devqa";
export const QA = "qa";
export const STG = "staging";
export const PROD = "production";

export const FLAG_DISABLED_CONFIG = {
    enabled: false
};

export const DOWNLOAD_FOR_VCF_OPS_SIGNED_ASSET = "DOWNLOAD_FOR_VCF_OPS_SIGNED_ASSET";
